<template>
  <section id="marriage">
    <v-container class="pa-8">
      <v-row
        class="my-5 px-5"
      >
        <v-col
          cols="12"
          md="4"
        >
          <v-card outlined>
            <v-img
              src="/static/asian-couple-argument.jpg"
              height="250"
            />
          </v-card>
        </v-col>

        <v-col
          class="px-0"
          cols="12"
          md="8"
        >
          <v-card
            flat
            color="transparent"
            class="px-5"
          >
            <v-card-title class="big-text">Marriage Counseling</v-card-title>

            <v-card-text class="pr-16">
              Te dolor consul mei, etiam molestie indoctum usu ad, qui inermis eleifend an. Pro iriure alienum incorrupte ex, cu diceret moderatius mei. Te corrumpit posidonium sit, in cum nonumy utamur. Usu impedit patrioque vituperatoribus ei, cu voluptua nominati philosophia mel. Sea at legendos percipitur, nulla dicant convenire ne duo. Veniam epicuri vis cu, ea pri alii ceteros fierent.
            </v-card-text>
            <v-btn large class="px-5 ml-5">
                Book Session
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        class="my-5 px-5"
      >
      <v-col
          cols="12"
        >
        <h3 class="med-text">What Doug Does</h3>
      </v-col>
      <v-col class="px-5">
        <p>Abuse</p>
        <p>Addictions</p>
        <p>Adoption Issues</p>
        <p>Anger Management</p>
        <p>Anxiety</p>
        <p>ADD</p>
        <p>ADHD</p>
        <p>Cognitive Behavioral Therapy</p>
        <p>Children</p>
        <p>Depression</p>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col class="px-5">
        <p>Abuse</p>
        <p>Addictions</p>
        <p>Adoption Issues</p>
        <p>Anger Management</p>
        <p>Anxiety</p>
        <p>ADD</p>
        <p>ADHD</p>
        <p>Cognitive Behavioral Therapy</p>
        <p>Children</p>
        <p>Depression</p>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col class="px-5">
        <p>Abuse</p>
        <p>Addictions</p>
        <p>Adoption Issues</p>
        <p>Anger Management</p>
        <p>Anxiety</p>
        <p>ADD</p>
        <p>ADHD</p>
        <p>Cognitive Behavioral Therapy</p>
        <p>Children</p>
        <p>Depression</p>
      </v-col>
      </v-row>
      <v-row
        class="my-5 px-5"
      >
        <v-col
          class="px-0"
          cols="12"
          md="8"
        >
          <v-card
            flat
            color="transparent"
          >
            <v-card-title class="big-text">Breaking down the walls.</v-card-title>

            <v-card-text class="pr-16">
              Te dolor consul mei, etiam molestie indoctum usu ad, qui inermis eleifend an. Pro iriure alienum incorrupte ex, cu diceret moderatius mei. Te corrumpit posidonium sit, in cum nonumy utamur. Usu impedit patrioque vituperatoribus ei, cu voluptua nominati philosophia mel. Sea at legendos percipitur, nulla dicant convenire ne duo. Veniam epicuri vis cu, ea pri alii ceteros fierent.
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-card outlined>
            <v-img
              src="/static/asian-couple-cooking-in-the-kitchen.jpg"
              height="250"
            />
          </v-card>
        </v-col>
      </v-row>
      
    </v-container>
    <AccentBar />
    <!-- <MidImage :bartype="'happy'"/> -->
    <ContactBar />
  </section>
</template>

<script>
  export default {
    metaInfo: {
      title: 'Services',
      meta: [
        { name: 'description', content: 'Customized vue-cli templates for Vue and Vuetify' }
      ]
    },

    components: {
      Heading: () => import('@/components/Heading'),
      Testimonial: () => import('@/components/Testimonial'),
      GetAQuote: () => import('@/components/GetAQuote'),
      ContactBar: () => import('@/components/ContactBar'),
      MidImage: () => import('@/components/MidImage'),
      AccentBar: () => import('@/components/AccentBar')
    },

    data: () => ({
      testimonial: {
        blurb: 'With Alpha Construction managing all of our customer-facing services and ASCC, helping us monitor and understand building performance, Abocado is in great shape to create and release new products.',
        person: 'Jay Oakrson',
        title: 'CEO Abocado'
      }
    })
  }
</script>
<style scoped>
.big-text {
    font-size: 3em;
}
.midbar-text {
    font-size: 1.5em;
}
</style>
